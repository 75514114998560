import { fetchGet, fetchPut } from './CommonServiceUtils';
import Constants from '../Constants';

const BASE_URL = `${Constants.BASE_URL}loyaltyservice/`;

const getLoyaltyConfig = () => {
    return fetchGet(BASE_URL + 'configs');
}

const updateConfig = ({ _id, ...rest }) => {
    return fetchPut(`${BASE_URL}configs/${_id}`, { ...rest });
}


export { getLoyaltyConfig, updateConfig }