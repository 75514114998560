import { fetchGet, fetchPost, jsonToQueryParam, fetchGetWithTotal, fetchPostWithTotal, fetchPut, fetchPostMultipart } from './CommonServiceUtils';
import Constants from '../Constants';


const BASE_URL = `${Constants.BASE_URL}coreservice/`;
// const BASE_URL = 'https://7hmi05mklj.execute-api.us-east-1.amazonaws.com/';

const uploadImage = (file) => {
    const formData = new FormData();
    formData.append("file", file);

    return fetchPostMultipart(`${BASE_URL}imageuploads`, formData);
}

const getContactAttributes = () => {
    return fetchGet(`${BASE_URL}attributes`);
}

const getSegmentCategories = () => {
    return fetchGet(`${BASE_URL}segmentcategories`);
}

const getSegments = () => {
    return fetchGet(`${BASE_URL}segments`);
}

const createSegments = (segmentDetails) => {
    return fetchPost(`${BASE_URL}segments`, segmentDetails);
}

const getActivities = (activityFilters) => {//{contactId, fromDate, toDate, eventId}
    return fetchGet(`${BASE_URL}activities?${jsonToQueryParam(activityFilters)}`);
}


const getContacts = ({ limit = 100, lastEvaluatedKey }) => {
    return fetchGetWithTotal(`${BASE_URL}contacts?${jsonToQueryParam({ limit, skip: lastEvaluatedKey || 0 })}`);
}

const filterContacts = ({ selectedFilters = [], limit = 100, lastEvaluatedKey, searchKey = '' }) => {
    return fetchPostWithTotal(`${BASE_URL}contacts/filter?${jsonToQueryParam({ limit, skip: lastEvaluatedKey || 0, searchKey })}`, selectedFilters);
}

const getContactById = (contactId) => {
    return fetchGet(`${BASE_URL}contacts/${contactId}`);
}


const updateContact = (id, { _created_on, _modified_on, _last_seen_on, _id, user_id, ...rest }) => {
    return fetchPut(`${BASE_URL}contacts/${id}`, rest);
}

const getEvents = () => {
    return fetchGet(`${BASE_URL}events`);
}


export {
    uploadImage, getContactAttributes, getSegmentCategories, getSegments,
    getActivities,
    getContacts, filterContacts, getContactById, updateContact,
    createSegments,
    getEvents
}