import React, { useContext } from 'react';
import { UserContext } from './Contexts/userContext';
import { Route } from 'react-router-dom';
import {LoadingComponent} from './Components/utils/UtilComponents';

const AuthRoute = ({ component: Component, routeName, ...rest }) => {

    const { isAuth } = useContext(UserContext);


    return (<Route
        {...rest}
        render={props =>

            isAuth ? (
                <Component {...props} routeName={routeName} />
            ) :
                <LoadingComponent />
        }
    />);
}

export default AuthRoute;