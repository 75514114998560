import React, { useReducer, useEffect, useCallback, useContext } from "react";
import { getProfile } from '../Services';
//import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
//import UserAccessTemplates, { ACCESS_CONTROL_DATA } from '../UserAccessTemplates';
//import { useHistory } from 'react-router-dom';
import { KeycloakContext } from './keycloakAuthServiceContext';
const UserContext = React.createContext()

const UserContextActions = {
    // LOGIN: 'login',
    // LOGOUT: 'logout',
    // INIT_COMPLETE: "userInitCompleted",
    UPDATE_USER_DATA: "updateUserData"
};

const initialState = {

    // username: "",
    // exp: null,
    // ownerId: null,
    // // initComplete: true,
    // scopes: [],
    // email: "",
    // mobileNumber: ""
};


const reducer = (state, action) => {
    switch (action.type) {
        // case UserContextActions.LOGOUT: {
        //     // localStorage.removeItem("token");
        //     return { ...initialState, initComplete: true };
        // }
        // case UserContextActions.LOGIN: {

        //     // const { userRole, scopes } = action;


        //     // let userScopes = [];
        //     // if (Array.isArray(scopes)) {
        //     //     userScopes = Object.values([...ACCESS_CONTROL_DATA, ...scopes].reduce((result, {
        //     //         moduleId,
        //     //         ...rest
        //     //     }) => {
        //     //         result[moduleId] = {
        //     //             ...(result[moduleId] || {}),
        //     //             moduleId,
        //     //             ...rest
        //     //         };
        //     //         return result;
        //     //     }, {}))
        //     // } else if (userRole) {
        //     //     if (UserAccessTemplates[userRole]) {
        //     //         userScopes = UserAccessTemplates[userRole];
        //     //     } else {
        //     //         userScopes = UserAccessTemplates.user;
        //     //     }
        //     // }

        //     // const userScopeTransformed = userScopes.reduce((result, item) => {
        //     //     result[item.moduleId] = item;
        //     //     return result;
        //     // }, {})

        //     // return { ...state, isAuth: true, username: action.username, email: action.email, roles: action.roles, token: action.token, exp: action.exp, scopes: userScopeTransformed };
        //     return { ...state, isAuth: true };
        // }

        case UserContextActions.UPDATE_USER_DATA: {
            const { type, ...rest } = action;
            return {
                ...state,
                ...rest
            }
        }
        // case UserContextActions.INIT_COMPLETE: {
        //     return { ...state, initComplete: true };
        // }
        default:
            return state;
    }
};

const constantMock = window.fetch;

const UserContextProvider = withRouter((props) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { token, keycloakLogout, isAuth, user } = useContext(KeycloakContext);
    // const history = useHistory();

    const logout = useCallback(() => {
        window.fetch = constantMock;
        // dispatch({ type: UserContextActions.LOGOUT });
        keycloakLogout();
    }, [keycloakLogout])

    const loadProfile = useCallback(async () => {
        try {
            const profileResponse = await getProfile();
            console.debug("Profile:", profileResponse);
            dispatch({ type: UserContextActions.UPDATE_USER_DATA, ...profileResponse });
        } catch (e) {

        }
    }, [dispatch])

    const login = useCallback((token) => {
        // localStorage.setItem("token", token);
        // const { so_user_name: username, so_user_email: email, so_user_role: userRole, so_user_id: ownerId, exp, scopes } = decodeToken(token);

        // dispatch({ type: UserContextActions.LOGIN, userRole, username, email, token: token, ownerId, exp, scopes });

        window.fetch = async function () {
            // Get the parameter in arguments
            // Intercept the parameter here 
            const body = arguments[1];
            if (body.headers['x-auth']) {
                delete body.headers['x-auth'];
                // if (isTokenTimeNotExpired(exp)) {
                body.headers['Authorization'] = "Bearer " + token;
                // } else {
                //     logout();
                //     history.push("/login");
                //     return;
                // }
            }

            const response = await constantMock.apply(this, arguments);

            if (response.status === 401 || response.status === 403) {//token expired
                logout();
                // history.push("/login");
            }

            return response
        }

       
    }, [logout])


    // useEffect(() => {
    //     const queryParams = queryString.parse(props.location.search);
    //     if (queryParams['token']) {
    //         const { token } = queryParams;
    //         login(token);
    //         history.push("/");

    //     } else {
    //         const token = localStorage.getItem('token');
    //         if (token) {
    //             login(token);
    //         }
    //     }
    //     dispatch({ type: UserContextActions.INIT_COMPLETE });
    // }, [history, props.location.search]);

    useEffect(() => {
        if (token) {
            login(token);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token])

    const value = { ...state, isAuth, username: user?.username, email: user?.email, login, logout, loadProfile };

    return (
        <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
    );
})

const UserContextConsumer = UserContext.Consumer;

export { UserContext, UserContextProvider, UserContextConsumer, UserContextActions };

