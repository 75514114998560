import React from 'react';
import './LoadingComponent.css';

const Loading = () => {
    return (
        <div className="loading-component" data-testid="loading-component">
            <div className="loader-content" data-testid="loading-content"></div>
        </div>
    );
};
export default Loading;