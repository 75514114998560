import { fetchGet, fetchPut, fetchPost, fetchDelete, fetchPostMultipart } from './CommonServiceUtils';
import Constants from '../Constants';
const BASE_URL = `${Constants.BASE_URL}loyaltyservice/`;


const createRewards = (rewardObj) => {
    return fetchPost(BASE_URL + "rewards", rewardObj);
}

const getRewardsList = () => {
    return fetchGet(BASE_URL + "rewards");
}

const getReward = (rewardId) => {
    return fetchGet(`${BASE_URL}rewards/${rewardId}`);
}

const updateReward = ({ _id, name, points, imageUrls, metadata = {}, userRedeemable, status, dailyRedemptionLimit }) => {
    const { countPerRedemption, couponResult, totalCount, usedCount, ...rest } = metadata;
    return fetchPut(BASE_URL + "rewards/" + _id, { name, points, imageUrls, metadata: { ...rest }, userRedeemable, status, dailyRedemptionLimit });
}

const deleteRewards = (rewardId) => {
    return fetchDelete(BASE_URL + "rewards/" + rewardId);
}

const addCodes = (_id, codes=[], codesCount) => {

    if (codes.length > 0) {
        return fetchPost(BASE_URL + "rewards/topup/" + _id, { codes });
    }
    else if (codesCount > 0) {
        return fetchPost(BASE_URL + "rewards/topup/" + _id, { codesCount });
    }
}

const topUpFileUpload = (_id, file) => {
    return fetchPostMultipart(BASE_URL + "rewards/topupfile/" + _id, file);
}

const getRewardRedemptionLog = (rewardId) => {
    return fetchGet(`${BASE_URL}redemptionlogs?rewardId=${rewardId}`);
}

const getRedeemAuthorize = (status) => {
    return fetchGet(`${BASE_URL}redeemauthorize${status ? '?status=' + status : ''}`);
}

const rejectRedeemReward = (id) => {
    return fetchPost(`${BASE_URL}redeemauthorize`, {
        "_id": id,
        "status": "REJECTED"
    }
    )
}

const approveRedeemReward = (id) => {
    return fetchPost(`${BASE_URL}redeemauthorize`, {
        "_id": id,
        "status": "APPROVED"
    }
    )
}

const getRewardRedemptionLogSummary = (fromDate, toDate) => {
    return fetchGet(`${BASE_URL}redemptionlogs/summary`);

}

export { createRewards, getRewardsList, getReward, updateReward, deleteRewards, getRewardRedemptionLog, getRewardRedemptionLogSummary, getRedeemAuthorize, rejectRedeemReward, approveRedeemReward, addCodes, topUpFileUpload }
