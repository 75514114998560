import React from 'react';
import './App.css';
// To do remove the default and customize a BS 4.5 Stylesheet for the theme. 
import '@shoutout-labs/shoutout-themes-enterprise-legacy/es/themes/default/bootstrap.min.css';
import '@shoutout-labs/shoutout-themes-enterprise-legacy/es/themes/enterprise-loyalty/bootstrap.min.css';
import { Switch } from 'react-router-dom';
import { UserContextProvider } from './Contexts/userContext';
import { DataContextProvider } from './Contexts/dataContext';
import { KeycloakContextProvider } from './Contexts/keycloakAuthServiceContext';
import Home from './Components/home/Home'
import LoginPage from './Components/login/Login';
import AuthRoute from './AuthRoute';
import CustomRoute from './CustomRoute';
import Notifications from "react-notify-toast";
const App = () => {

  return (
    <div className="App h-100">
      <Notifications options={{ zIndex: 10020 }} />
      <KeycloakContextProvider>
        <UserContextProvider >
          <DataContextProvider>
            <Switch>
              <CustomRoute name="LoginPage" exact path="/login" component={LoginPage} />
              <AuthRoute path="/" component={Home} />
            </Switch>
          </DataContextProvider>
        </UserContextProvider >
      </KeycloakContextProvider>
    </div>
  );
}

export default App;
