import React from 'react'
import { SideNavigationBar, Image, FaIcon } from '@shoutout-labs/shoutout-themes-enterprise-legacy';
import { faChartLine, faTachometerAlt, faUsers, faGift, faDollarSign, faMedal,faCoins, faUser, faBell, faAddressCard } from '@shoutout-labs/shoutout-themes-enterprise-legacy/es/FaIconsSolid';
import { useLocation, NavLink } from "react-router-dom";
import logo from './../../assets/images/logo_full.png'
import './Navigation.css';

const Navigation = () => {
   // const history = useHistory();
    const location = useLocation();

    // const onClickNavButton = useCallback((path) => {
    //     history.push(path)
    // }, [history])

    return (
        <div className="sidebar-wrapper bg-black shadow-lg h-100">
            <SideNavigationBar>
                <div className="sidebar-container">
                    <div className="side-navigation-nav-link pt-3 pb-5">
                        <Image className="logo" src={logo} alt="ShoutOUT" />
                    </div>
                    <NavLink to="/" className={`link mt-5 text-${location.pathname === '/' ? "primary" : "dark"} `} id="side-nav-link-dashboard">
                        <FaIcon icon={faTachometerAlt} className="mr-2" />
                        <span className="link-text">Dashboard</span>
                    </NavLink>

                    <NavLink to="/members" className={`link text-${location.pathname.startsWith('/members') ? "primary" : "dark"} `} id="side-nav-link-dashboard">
                        <FaIcon icon={faUsers} className="mr-2" />
                        <span className="link-text">Members</span>
                    </NavLink>

                    <NavLink to="/points" className={`link text-${location.pathname.startsWith('/points') ? "primary" : "dark"} `} id="side-nav-link-dashboard">
                        <FaIcon icon={faCoins} className="mr-2" />
                        <span className="link-text">Points</span>
                    </NavLink>

                    <NavLink to="/rewards" className={`link text-${location.pathname.startsWith('/rewards') ? "primary" : "dark"} `} id="side-nav-link-dashboard">
                        <FaIcon icon={faGift} className="mr-2" />
                        <span className="link-text">Rewards</span>
                    </NavLink>

                    <NavLink to="/cards" className={`link text-${location.pathname.startsWith('/cards') ? "primary" : "dark"} `} id="side-nav-link-dashboard">
                        <FaIcon icon={faAddressCard} className="mr-2" />
                        <span className="link-text">Cards</span>
                    </NavLink>

                    <NavLink to="/tiers" className={`link text-${location.pathname.startsWith('/tiers') ? "primary" : "dark"} `} id="side-nav-link-dashboard">
                        <FaIcon icon={faMedal} className="mr-2" />
                        <span className="link-text">Tiers</span>
                    </NavLink>


                    <NavLink to="/transactions" className={`link text-${location.pathname.startsWith('/transactions') ? "primary" : "dark"} `} id="side-nav-link-dashboard">
                        <FaIcon icon={faDollarSign} className="mr-2" />
                        <span className="link-text">Transactions</span>
                    </NavLink>


                    <NavLink to="/notifications" className={`link text-${location.pathname.startsWith('/notifications') ? "primary" : "dark"} `} id="side-nav-link-dashboard">
                        <FaIcon icon={faBell} className="mr-2" />
                        <span className="link-text">Notifications</span>
                    </NavLink>


                    <NavLink to="/analytics" className={`link text-${location.pathname.startsWith('/analytics') ? "primary" : "dark"} `} id="side-nav-link-dashboard">
                        <FaIcon icon={faChartLine} className="mr-2" />
                        <span className="link-text">Analytics</span>
                    </NavLink>

                    <NavLink to="/profile" className={`link mt-auto mb-3 text-${location.pathname.startsWith('/profile') ? "primary" : "dark"} `} id="side-nav-link-dashboard">
                        <FaIcon icon={faUser} className="mr-2" />
                        <span className="link-text">Profile</span>
                    </NavLink>
                </div>



                {/* <NavLink active={location.pathname === '/'} icon={faTachometerAlt} name="Dashboard" navlinkTextClassNames="nav-styles d-block" onClick={()=>{onClickNavButton('/')}}/> */}
                {/* <SideNavigationBar.NavLink active={location.pathname.startsWith('/members')} icon={faUsers} name="Members" navlinkTextClassNames="nav-styles d-block" onClick={() => { onClickNavButton('/members') }} /> */}
                {/* <SideNavigationBar.NavLink active={location.pathname.startsWith('/configurations')} icon={faCogs} name="Configuration" navlinkTextClassNames="nav-styles d-block" onClick={()=>{onClickNavButton('/configurations')}}/>  */}

            </SideNavigationBar>
        </div>
    )
}

export default Navigation