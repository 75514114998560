import { fetchGet, fetchPut, fetchPost, fetchDelete,jsonToQueryParam } from './CommonServiceUtils';
import Constants from '../Constants';
const BASE_URL = `${Constants.BASE_URL}loyaltyservice/`;

const createCardRequest = (cardObj) => {
    return fetchPost(BASE_URL + "cardrequests", cardObj);
}

const getCardRequests = (queryObj) => {
    return fetchGet(`${BASE_URL}cardrequests?${jsonToQueryParam(queryObj)}`);
}

const getCardRequest = (id) => {
    return fetchGet(`${BASE_URL}cardrequests/${id}`);
}

const editCardRequest = (id,editedCardObj) => {
    return fetchPut(`${BASE_URL}cardrequests/${id}`, editedCardObj);
}

const createCardPrintJob = (cardRequestIds) => {
    return fetchPost(BASE_URL + "cardprintjobs", {cardRequestIds});
}

const postCards = (cardRequestIds) => {
    return fetchPut(`${BASE_URL}cardrequests/bulk/post`, {cardRequestIds});
}

const getCardPrintJobs = (queryObj) => {
    return fetchGet(`${BASE_URL}cardprintjobs?${jsonToQueryParam(queryObj)}`);
}

const getCardPrintJob = (id) => {
    return fetchGet(`${BASE_URL}cardprintjobs/${id}`);
}

const updateCardPrintJobStatus = (id,cardPrintStatus,letterPrintStatus) => {
    return fetchPut(`${BASE_URL}cardprintjobs/${id}`, {cardPrintStatus,letterPrintStatus});
}

const deleteCardPrintJobBatch = (id) => {
    return fetchDelete(`${BASE_URL}cardprintjobs/${id}`)
}

const getPrintJobSuccess = (id, type) => {
    return fetchGet(`${BASE_URL}cardprintjobs/document/${id}?${jsonToQueryParam({type: type})}`);
}

const getCardRequestsCount = (queryObj) => {
    return fetchGet(`${BASE_URL}cardrequestsstats/counts?${jsonToQueryParam(queryObj)}`);
}

const getCardRequestsSeries = (queryObj) => {
    return fetchGet(`${BASE_URL}cardrequestsstats/series?${jsonToQueryParam(queryObj)}`);
}

export { createCardRequest,getCardRequests,getCardRequest,editCardRequest,createCardPrintJob,getCardPrintJobs,getCardPrintJob,updateCardPrintJobStatus,deleteCardPrintJobBatch,getPrintJobSuccess,getCardRequestsCount,getCardRequestsSeries,postCards }