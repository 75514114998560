import React from 'react';

import {  Route } from 'react-router-dom';
const CustomRoute = ({ component: Component,routeName, ...rest }) => {
    return (<Route
        {...rest}
        render={props =>(
                <Component {...props} routeName={routeName}/>
            )
        }
    />);
}

export default CustomRoute;