import ShoutOUTLogo from './assets/images/shoutout_logo.png';
import DilmahLogo from './assets/images/dilmah/dilmah.svg';
const DefaultContext = {
    logo: ShoutOUTLogo,
    keycloakConfig:`${process.env.PUBLIC_URL}/assets/config/keycloak.json`,
    baseUrl:"https://enterprise.api.topnotch.club/api/"
}
const DimahContext = {
    logo: DilmahLogo,
    keycloakConfig:`${process.env.PUBLIC_URL}/assets/config/dilmah/keycloak.json`,
    baseUrl:"https://loyalty-api.dilmaht-lounge.com/api/"
}

const DevContext = {
    logo: ShoutOUTLogo,
    keycloakConfig:`${process.env.PUBLIC_URL}/assets/config/keycloakdev.json`,
    baseUrl:"https://betaenterprise.api.topnotch.club/api/"
}

let context;


switch (process.env.REACT_APP_BUILD_VARIANT) {
    case "dilmah": {
        context = DimahContext;
        break;
    }
    case "dev" : {
        context = DevContext;
        break;
    }
    default: {
        context = DefaultContext;
        break;
    }
}

export default context;