import React, { useEffect} from 'react'
import { Button} from '@shoutout-labs/shoutout-themes-enterprise-legacy';

const LoginPage = () => {
    useEffect(() => {
        window.location.replace(`https://getshoutout.com/logout?referrer=${window.location.origin}`);
    }, []);
    return (<div className="mt-5 text-center" data-testid="login">
        <h1 className="text-center" data-testid="redirecting">Redirecting...</h1>
        <Button variant="info" role="button">Go to Login</Button>
    </div>)
}

export default LoginPage
